import React from 'react'
import { graphql, useStaticQuery, Link} from 'gatsby'
import {ListGroup}  from 'react-bootstrap';

export default function LeftNavBarPHPProject() {
    const data = useStaticQuery(graphql`
    query phpProjects {
        basic: allMarkdownRemark(
          limit: 25
          filter: {fileAbsolutePath: {glob: "**/tutorials/php/*"}, 
            frontmatter: {difficulty: {eq: 3}}}
          sort: {fields: frontmatter___order, order: ASC}
        ) {
          nodes {
            frontmatter {
              slug
              title
            }
            id
          }
        }
      }
      
  `)

    const menu_items = data.basic.nodes

    return (
        <ListGroup>
            {menu_items.map(item => (
                <ListGroup.Item key={item.id}>
                 <Link activeStyle={{ color: "red" }} to={"/" + item.frontmatter.slug} key={item.id}>
                    {'PHP 8 - ' + item.frontmatter.title}
                </Link>  
                </ListGroup.Item>
            ))}
        </ListGroup>
    )
}
